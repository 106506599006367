export const BASE_URL =
  process.env.REACT_APP_API_ENV === 'development'
    ? 'https://im-api-df-backend-dev.dev.info-logistics.eu/'
    : `https://api.${window.location.hostname.replace(/^[^.]+\./g, '')}/`

export const Languages = {
  EN: 'en',
  RU: 'ru',
  PT: 'pt'
}

export const lookupLocalStorage = 'i18nextLng'

export const PATHNAME_SEPARATOR = '/'

export const NS = [
  'common',
  'document',
  'language',
  'notification'
]

export enum i18nEvent {
  LANGUAGE_CHANGED = 'languageChanged'
}

export const INNER_CLASSES = 'full-width px-4 inner mx-auto'

export const DEBOUNCE_WAIT = 100

export const MOBILE_MAX_WIDTH = 767

export const ResponseCode = {
  DEL: 204,
  GET: 200,
  POST: 201,
  PUT: 201,
  ERROR: 400
}

export enum FieldKey {
  DIRECTION = 'direction',
  DOCUMENT_DATE = 'documentDate',
  DOCUMENT_NUMBER = 'documentNumber',
  SUM_TOTAL = 'sumTotal',
  SUM_VAT = 'sumVat',
  SUM_WITHOUT_VAT = 'sumWithoutVat'
}

export enum DateFormat {
  DATE_FULL_YEAR = 'dd.MM.yyyy',
  DATETIME = 'dd.MM.yy HH:mm',
  DATETIME_FULL_YEAR = 'dd.MM.yyyy HH:mm'
}

export enum HistoryStateGroup {
  APPROVING = 'APPROVING',
  CREATING = 'CREATING',
  DECLINING = 'DECLINING',
  QUESTION = 'QUESTION',
  RECEIVING = 'RECEIVING',
  SENDING = 'SENDING',
  SIGNING = 'SIGNING',
  UNKNOWN = 'UNKNOWN'
}

export enum Severity {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING'
}

export const SEVERITY_COLORS = {
  [Severity.ERROR]: 'danger-500',
  [Severity.INFO]: 'info-500',
  [Severity.SUCCESS]: 'success-500',
  [Severity.UNKNOWN]: 'gray-500',
  [Severity.WARNING]: 'warning-500'
}

export enum Browser {
  CHROME = 'chrome',
  EDGE = 'edge-chromium',
  FIREFOX = 'firefox',
  OPERA = 'opera'
}

export enum OS {
  ANDROID = 'Android OS'
}

export enum Instance {
  GLOBAL = 'global',
  EU = 'eu',
  RU = 'ru',
  BR = 'com.br',
  ZA = 'africa'
}
